import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { formatDateTimeDDMMYYHHMM } from '@core/utils/utils'

export default function usePhysicianList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)
  const isProcessing = ref(false)
  const timeoutId = ref(null)
  const refreshes = ref(0)

  // Table Handlers
  const tableColumns = [
    { key: 'original_name', sortable: false, label: 'Arquivo' },
    { key: 'created_by.name', sortable: false, label: 'Criado por' },
    { key: 'status', sortable: false, label: 'Status' },
    {
      key: 'created_at',
      sortable: false,
      label: 'Criado Em',
      formatter: date => formatDateTimeDDMMYYHHMM(new Date(date)),
    },
    { key: 'actions', label: 'Ações' },
  ]
  const perPage = ref(25)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('created_at')
  const isSortDirDesc = ref(true)
  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  const onUnmountedList = () => {
    clearTimeout(timeoutId.value)
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const getReportParams = () => ({
    q: searchQuery.value,
    perPage: perPage.value,
    page: currentPage.value,
    sortBy: sortBy.value,
    sortDirection: isSortDirDesc.value ? 'desc' : 'asc',
  })

  const updateIsProcessing = items => {
    if (timeoutId.value) clearInterval(timeoutId.value)
    isProcessing.value = items.some(({ status }) => status === 'processing')

    if (isProcessing.value && refreshes.value < 20) {
      const time = (refreshes.value + 1) * 10000
      timeoutId.value = setTimeout(() => {
        refreshes.value += 1
        refetchData()
      }, time)
    }
  }

  const fetchPhysicians = (ctx, callback) => {
    store
      .dispatch('app-physician/fetchPhysicians', getReportParams())
      .then(response => {
        const { data: users, meta } = response.data
        updateIsProcessing(users)
        callback(users)
        totalUsers.value = meta.pagination.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao buscar lista',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const uploadFile = file => store
    .dispatch('app-physician/uploadFile', { file })
    .then(() => {
      //
    })
    .catch(error => {
      let title = 'Erro ao enviar arquivo'

      const { message } = error.response.data
      if (message === 'file already uploaded') {
        title = 'Arquivo já enviado'
      } else if (message === 'required columns not present') {
        title = 'Colunas faltantes'
      }

      toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const fetchPhysicianImport = file => store
    .dispatch('app-physician/fetchPhysicianImport', { file })
    .then(response => response.data)
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Erro carregar detalhes',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  return {
    fetchPhysicianImport,
    fetchPhysicians,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    refetchData,
    uploadFile,
    onUnmountedList,
  }
}
