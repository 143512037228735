import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPhysicians(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/physician/imports', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPhysicianImportItems(ctx, { id, ...data }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/physician/imports/${id}/items`, { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPhysicianImport(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/physician/imports/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadFile(ctx, { file }) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('file', file)
        axios
          .post('/physician/imports', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
